import React from 'react';

import { getAuthType } from 'state/auth/selectors/authSelectors';

import { AuthType } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { snapPublisherEmbedCacheUrl, snapPublisherSnapAuthHandshake } from 'utils/snapPublisherUtils';

import type { State } from 'types/rootState';

type OwnProps = {
  handshake?: boolean;
};

type StateProps = {
  embedCacheUrl: string;
  snapAuthHandshake: string;
  isSnapSSO: boolean;
};

type Props = OwnProps & StateProps;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const authType = getAuthType(state);
  const isSnapSSO = authType === AuthType.SNAPCHAT;
  return {
    snapAuthHandshake: snapPublisherSnapAuthHandshake(),
    embedCacheUrl: snapPublisherEmbedCacheUrl(isSnapSSO),
    isSnapSSO,
  };
};

export const SnapPublisherPreCache = (props: Props) => {
  if (!props.isSnapSSO) {
    return null;
  }

  return (
    <div>
      <iframe src={props.embedCacheUrl} style={{ display: 'none' }} data-test="SnapPublisherPreCache.embedCacheUrl" />
      {props.handshake && (
        <iframe
          src={props.snapAuthHandshake}
          style={{ display: 'none' }}
          data-test="SnapPublisherPreCache.snapAuthHandshake"
        />
      )}
    </div>
  );
};

export default intlConnect(mapStateToProps, null)(SnapPublisherPreCache);
