import classNames from 'classnames';
import * as React from 'react';

import Icon from '../Icon/Icon';

import { warningTriangle, help } from 'icons/SDS/allIcons';

import style from './DotStatus.scss';

export enum DotStatusState {
  INFO,
  INCOMPLETE,
  ERROR,
}

const DotStatus: React.FC<{ status: DotStatusState }> = ({ status }) => {
  return (
    <div
      className={classNames(style.dotStatus, {
        [style.error]: status === DotStatusState.ERROR,
        [style.incomplete]: status === DotStatusState.INCOMPLETE,
        [style.info]: status === DotStatusState.INFO,
      })}
    >
      <Icon inlineIcon={status === DotStatusState.INFO ? help : warningTriangle} />
    </div>
  );
};

export default DotStatus;
