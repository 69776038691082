import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/x.svg.inline' or its cor... Remove this comment to see the full error message
import xIcon from 'icons/x.svg.inline';

import style from './DeletedStoryTopsnapPreview.scss';

export default class DeletedStoryTopsnapPreview extends React.PureComponent<{}> {
  render() {
    return (
      <div draggable="false" className={style.emptySnapPreview}>
        <InlineSVG src={xIcon} className={style.xIcon} />
      </div>
    );
  }
}
