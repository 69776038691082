import classNames from 'classnames';
import invariant from 'invariant';
import { get } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as previewsActions from 'state/previews/actions/previewsActions';
import * as previewsSelectors from 'state/previews/selectors/previewsSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getPresentationalTileForSnap } from 'state/publisherTools/selectors/publisherToolsSelectors';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import TilePreview from '../../../editor/containers/TilePreview/TilePreview';

import { DropzoneType, UploadPurpose, CrossOrigin } from 'config/constants';
import { TileFormatConfig, TileFormat } from 'config/tileConfig';
import type { TileConfig } from 'config/tileConfig';
import { intlConnect } from 'utils/connectUtils';
import * as assetUtils from 'utils/media/assetUtils';
import * as videoUtils from 'utils/media/videoUtils';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';
import VideoPreviewLoadingBar from 'views/common/components/VideoPreviewLoadingBar/VideoPreviewLoadingBar';
import MediaUploader from 'views/editor/containers/MediaUploader/MediaUploader';

import style from './StoryTopsnapPreview.scss';

import type { EditionID } from 'types/editions';
import type { State } from 'types/rootState';
import { SnapType } from 'types/snaps';
import type { TopSnap, Snap } from 'types/snaps';
import type { Tile } from 'types/tiles';

const VIDEO_PROGRESS_POLLING_INTERVAL_MS = 100;
type OwnProps = {
  'data-test'?: string;
  editionId: EditionID;
  snap: TopSnap;
  // Whether video should be muted
  muted: boolean;
};
type StateProps = {
  className?: string;
  defaultTilesFormat: TileConfig;
  firstTile: Tile | undefined | null;
  forceReadOnly?: boolean;
  isActiveEditionReadOnly: boolean;
  isAnySnapBeingDragged?: boolean;
  isShowTilesEnabled: boolean;
  onClick?: () => void;
  renderTile?: boolean;
  snapHasTransactionOrIsUploading: boolean;
  readOnlyMediaUploader?: boolean;
  snapPreview: string | undefined | null;
};
type DispatchProps = {
  loadSnapPreviewIfMissing: typeof previewsActions.loadSnapPreviewIfMissing;
};
type OwnState = {
  isHovering: boolean;
  videoLoaded: boolean;
  imageLoaded: boolean;
  progressPercentage: number;
};
type OwnStoryTopsnapPreviewProps = OwnProps & StateProps & DispatchProps;
const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
  const firstTile = getPresentationalTileForSnap(state)(ownProps.snap.id, ownProps.editionId);
  return {
    snapPreview: previewsSelectors.getSnapPreviewById(state)(ownProps.snap.id),
    firstTile,
    snapHasTransactionOrIsUploading: editorSelectors.snapHasTransactionOrIsUploading(state)({
      snapId: ownProps.snap.id,
    }),
    isActiveEditionReadOnly: publisherStoryEditorSelectors.getActiveEditionIsReadOnly(state),
    isShowTilesEnabled: userSelectors.getShowTilesEnabled(state),
    defaultTilesFormat: TileFormatConfig[TileFormat.CHEETAH_DEFAULT],
  };
};
const mapDispatchToProps = {
  loadSnapPreviewIfMissing: previewsActions.loadSnapPreviewIfMissing,
};
type Props = OwnStoryTopsnapPreviewProps & typeof StoryTopsnapPreview.defaultProps;
export class StoryTopsnapPreview extends React.Component<Props, OwnState> {
  static defaultProps = {
    onClick: () => {},
    forceReadOnly: false,
    readOnlyMediaUploader: false,
    renderTile: true,
    isAnySnapBeingDragged: false,
  };

  state = {
    isHovering: false,
    videoLoaded: false,
    imageLoaded: false,
    progressPercentage: 0,
  };

  /* eslint-disable react/sort-comp */
  dragCounter = 0;

  mediaUploader = null;

  video: HTMLVideoElement | undefined | null;

  videoPlay: Promise<void> | null = null;

  image: HTMLImageElement | undefined | null;

  updateInterval: NodeJS.Timeout | null = null;

  /* eslint-enable react/sort-comp */
  UNSAFE_componentWillMount() {
    this.props.loadSnapPreviewIfMissing(this.props.snap.id);
  }

  componentWillUnmount() {
    this.disableProgressPolling();
  }

  onPreviewEnter = () => {
    this.setState({ isHovering: true });
    if (this.props.snap.type === SnapType.VIDEO || this.props.snap.type === SnapType.SINGLE_ASSET) {
      this.startVideo();
      this.enableProgressPolling();
    }
  };

  onVideoLoaded = () => {
    this.setState({ videoLoaded: true });
  };

  onImageLoaded = () => {
    this.setState({ imageLoaded: true });
  };

  onPreviewLeave = () => {
    this.setState({ isHovering: false });
    if (this.props.snap.type === SnapType.VIDEO || this.props.snap.type === SnapType.SINGLE_ASSET) {
      this.stopVideo();
    }
  };

  setProgress = () => {
    if (this.video) {
      const totalDuration = this.video.duration;
      const { currentTime } = this.video;
      const progressPercentage = Math.floor((100.0 * currentTime) / totalDuration);
      this.setState({
        progressPercentage,
      });
    }
  };

  // We need to pool the progress of the video at regular intervals to make sure that the loading bar
  // is not too jumpy. Updates from the `currentTime` listener do not come in regular intervals, so the loading bar
  // would look jumpy.
  // This does not affect performance as the interval is only triggered if the user is hovering over the component.
  enableProgressPolling = () => {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    this.updateInterval = setInterval(this.setProgress, VIDEO_PROGRESS_POLLING_INTERVAL_MS);
  };

  disableProgressPolling = () => {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  };

  // Multiple enter and leave events may be emitted because they're emitted for each child that is dragged over
  onDragEnter = () => {
    this.dragCounter = this.dragCounter || 0;
    this.dragCounter++;
    if (this.dragCounter === 1) {
      this.onPreviewEnter();
    }
  };

  onDragLeave = () => {
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.onPreviewLeave();
    }
  };

  getTile() {
    if (this.shouldRenderTile()) {
      return this.props.firstTile;
    }
    return null;
  }

  startVideo = () => {
    const videoDOMobject = this.video;
    if (videoDOMobject && videoDOMobject instanceof HTMLMediaElement) {
      if (videoDOMobject.paused) {
        videoDOMobject.currentTime = 0;
        this.videoPlay = videoDOMobject.play().catch((e: any) => {
          // Playback has been interrupted because of another pause() call. Just swallow it
          // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        });
      }
    }
  };

  stopVideo = () => {
    const videoDOMobject = this.video;
    if (videoDOMobject && videoDOMobject instanceof HTMLMediaElement) {
      if (!videoDOMobject.paused && this.videoPlay) {
        this.videoPlay.then(() => {
          videoDOMobject.pause();
          videoDOMobject.currentTime = 0;
        });
        this.videoPlay = null;
      }
    }
    this.disableProgressPolling();
    this.setState({
      progressPercentage: 0,
    });
  };

  isReadOnly() {
    return this.props.isActiveEditionReadOnly || this.props.forceReadOnly;
  }

  shouldRenderTile() {
    const { renderTile, isShowTilesEnabled } = this.props;
    // Don't show if user doesn't want
    if (!isShowTilesEnabled) {
      return false;
    }
    const isSubscribeSnap = snapEntityHelpers.isSubscribeSnap(this.props.snap);
    // firstTile is null return false as tile is not required
    if (this.props.firstTile === null) {
      return false;
    }
    return renderTile && !isSubscribeSnap;
  }

  onVideoRef = (video?: HTMLVideoElement | null) => {
    const node = ReactDOM.findDOMNode(video);
    invariant(!node || node instanceof HTMLVideoElement, 'node is an HTML video element');
    this.video = node;
  };

  onImageRef = (image?: HTMLImageElement | null) => {
    const node = ReactDOM.findDOMNode(image);
    invariant(!node || node instanceof HTMLImageElement, 'node is an HTML image element');
    this.image = node;
  };

  getBitmojiStoriesUrl() {
    // Note(bkotsopoulos): Adding a custom avatar meant some image requests
    // from the iframe were being stalled by the browser. Leaving as default
    // avatar for now.
    return get(this.props.snap, 'remoteUrl');
  }

  renderTileOverlay() {
    if (this.shouldRenderTile()) {
      const overlayClasses = classNames({
        [style.tileOverlay]: true,
        [style.hide]: this.state.isHovering,
      });
      // Note: TilePreview scale and format match the ones in ComponentPanel
      return (
        <div className={overlayClasses}>
          <TilePreview
            className={style.tilePreviewOverlay}
            tile={this.getTile()}
            format={this.props.defaultTilesFormat}
            useSmallHeadline
          />
        </div>
      );
    }
    return null;
  }

  renderEmptyPreview() {
    return (
      <div
        draggable="false"
        className={classNames(this.props.className, style.emptySnapPreview)}
        onClick={this.props.onClick}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDragLeave}
        onMouseEnter={this.onPreviewEnter}
        onMouseLeave={this.onPreviewLeave}
        data-test={this.props['data-test']}
      >
        {this.renderTileOverlay()}
        {this.renderMediaUploader()}
        {snapEntityHelpers.isSubscribeSnap(this.props.snap) && (
          <div className={style.subscribeSnapText}>
            <FormattedMessage
              id="story-end-snap-header"
              defaultMessage="End Snap"
              description="Text displayed as a placeholder when end snap image preview doesnt exist"
            />
          </div>
        )}
      </div>
    );
  }

  linkMediaUploader = (element?: HTMLElement | null) => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element | Text | null' is not assignable to ... Remove this comment to see the full error message
    this.mediaUploader = ReactDOM.findDOMNode(element);
  };

  renderMediaUploader() {
    if (
      snapEntityHelpers.isSubscribeSnap(this.props.snap) ||
      snapEntityHelpers.topsnapHasMedia(this.props.snap) ||
      this.isReadOnly() ||
      !this.props.editionId
    ) {
      return null;
    }
    return (
      <MediaUploader
        snapId={this.props.snap.id}
        purpose={UploadPurpose.TOP_SNAP}
        dropzoneType={DropzoneType.TOPSNAP}
        className={classNames({
          [style.dropzone]: this.state.isHovering,
          [style.mediaUploader]: true,
        })}
        isReadOnly={
          this.props.snapHasTransactionOrIsUploading ||
          this.props.isAnySnapBeingDragged ||
          this.props.readOnlyMediaUploader
        }
        editionId={this.props.editionId}
        ref={this.linkMediaUploader}
        disableClick
        renderVideoInfo={false}
        uploadMessageClassName={style.uploadMessage}
      >
        {this.renderMediaUploaderInnerChild()}
      </MediaUploader>
    );
  }

  renderMediaUploaderInnerChild() {
    if (this.props.snapHasTransactionOrIsUploading) {
      return <SpinnerIcon className={style.spinner} />;
    }
    return null;
  }

  renderCameoContent(snap: Snap) {
    if (snap?.cameoSnapModel?.cameoId === 0) {
      return (
        <div data-test="StoryTopsnapPreview.renderCameoContent.zeroState" className={style.cameoWrap}>
          <FormattedMessage
            id="story-top-snamp-cameo-tile-data-error-id"
            description="Message shown when the cameo id is 0"
            defaultMessage="Cameo not set"
          />
        </div>
      );
    }
    if (!/^https:\/\/.*\.mp4\?/.test(snap?.cameoSnapModel?.previewUrl || '')) {
      return (
        <div
          data-test="StoryTopsnapPreview.renderCameoContent.errorState"
          className={classNames(style.cameoWrap, style.cameoError)}
        >
          <FormattedMessage
            id="story-top-snamp-cameo-tile-data-error-url"
            description="Error shown when the url to the mp4 is bad"
            defaultMessage="Bad Cameo tile"
          />
        </div>
      );
    }
    return (
      <div data-test="StoryTopsnapPreview.renderCameoContent.video" className={style.cameoWrap}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: string; drag... Remove this comment to see the full error message */}
        <video className={style.cameoVideo} draggable="false" alt="Cameo Tile" controls>
          <source src={snap?.cameoSnapModel?.previewUrl} type="video/mp4" />
        </video>
      </div>
    );
  }

  render() {
    const { snap } = this.props;
    // Bitmoji: Ignore the empty snapPreview field - previews are not triggered on that property.
    // CAMEOS_CONTENT: Ignore the empty snapPreview field - previews are not triggered on that property.
    if (!this.props.snapPreview && snap.type !== SnapType.BITMOJI_REMOTE_WEB && snap.type !== SnapType.CAMEOS_CONTENT) {
      return this.renderEmptyPreview();
    }
    const overlayMediaSrc = assetUtils.getImagePreviewUrl(this.props.snap.overlayImageAssetId);
    const overlayComponent = overlayMediaSrc && (
      <img
        draggable="false"
        src={overlayMediaSrc}
        crossOrigin={CrossOrigin.USE_CREDENTIALS}
        alt="Snap overlay"
        className={classNames(style.snapImagePreview, style.snapImageOverlay)}
      />
    );
    if (snap.type === SnapType.VIDEO || snap.type === SnapType.SINGLE_ASSET) {
      if (!snapEntityHelpers.topsnapHasVideoMedia(this.props.snap)) {
        return this.renderEmptyPreview();
      }
      const mediaSrc = assetUtils.getVideoPreviewUrl(snap.videoAssetId);
      const spectaclesTopsnap = get(this.props.snap, 'circular', false);
      const haveSpectaclesVideoDimensions = spectaclesTopsnap && this.state.videoLoaded;
      const circularSpectaclesVideo =
        haveSpectaclesVideoDimensions &&
        this.video &&
        videoUtils.isCircularVideo({
          width: this.video.videoWidth,
          height: this.video.videoHeight,
        });
      const haveSpectaclesImageDimensions = spectaclesTopsnap && this.state.imageLoaded;
      const circularSpectaclesImage =
        haveSpectaclesImageDimensions &&
        this.image &&
        videoUtils.isCircularVideo({
          width: this.image.naturalWidth,
          height: this.image.naturalHeight,
        });
      const videoClass = classNames({
        [style.snapVideoPreview]: true,
        [style.snapVideoNoPointerEvents]: this.shouldRenderTile(),
        [style.hide]: !this.state.isHovering || !this.state.videoLoaded,
        [style.circularSpectaclesPreview]: circularSpectaclesVideo,
        [style.rectangularSpectaclesPreview]: spectaclesTopsnap && !circularSpectaclesVideo,
      });
      const imageClass = classNames(style.snapImagePreview, style.videoImagePreview, {
        [style.hide]: (this.state.isHovering && this.state.videoLoaded) || !this.state.imageLoaded,
        [style.circularSpectaclesPreview]: circularSpectaclesImage,
        [style.rectangularSpectaclesPreview]: spectaclesTopsnap && !circularSpectaclesImage,
      });
      return (
        <div
          className={classNames(this.props.className, style.previewContainer)}
          onMouseEnter={this.onPreviewEnter}
          onMouseLeave={this.onPreviewLeave}
          onClick={this.props.onClick}
          data-test={this.props['data-test']}
        >
          {this.renderTileOverlay()}
          <img
            draggable="false"
            /* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message */
            src={this.props.snapPreview}
            alt="Snap preview"
            className={imageClass}
            onLoad={this.onImageLoaded}
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            ref={this.onImageRef}
          />
          <video
            draggable="false"
            /* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; draggable: "false"; alt... Remove this comment to see the full error message */
            alt="Snap preview"
            ref={this.onVideoRef}
            className={videoClass}
            onLoadedData={this.onVideoLoaded}
            poster={this.props.snapPreview || undefined}
            preload="none"
            muted={this.props.muted}
            loop
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
          >
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
            <source src={mediaSrc} />
          </video>
          {overlayComponent}
          {this.state.isHovering && (snap.type === SnapType.VIDEO || snap.type === SnapType.SINGLE_ASSET) ? (
            <VideoPreviewLoadingBar className={style.loadingBar} progressPercentage={this.state.progressPercentage} />
          ) : null}
        </div>
      );
    }
    if (snap.type === SnapType.IMAGE || snap.type === SnapType.UNKNOWN) {
      return (
        <div
          className={classNames(this.props.className, style.previewContainer)}
          onMouseEnter={this.onPreviewEnter}
          onMouseLeave={this.onPreviewLeave}
          onClick={this.props.onClick}
          data-test={this.props['data-test']}
        >
          {this.renderTileOverlay()}
          <img
            draggable="false"
            crossOrigin={CrossOrigin.USE_CREDENTIALS}
            src={this.props.snapPreview!}
            alt="Snap preview"
            className={style.snapImagePreview}
          />
          {overlayComponent}
        </div>
      );
    }
    if (snap.type === SnapType.BITMOJI_REMOTE_WEB) {
      return (
        <div
          className={classNames(this.props.className, style.previewContainer)}
          onMouseEnter={this.onPreviewEnter}
          onMouseLeave={this.onPreviewLeave}
          onClick={this.props.onClick}
          data-test={this.props['data-test']}
        >
          {this.renderTileOverlay()}
          <iframe src={this.getBitmojiStoriesUrl()} className={style.bitmojiStoriesPreview} />
          {overlayComponent}
        </div>
      );
    }
    if (snap.type === SnapType.CAMEOS_CONTENT) {
      return (
        <div
          className={classNames(this.props.className, style.previewContainer)}
          onMouseEnter={this.onPreviewEnter}
          onMouseLeave={this.onPreviewLeave}
          onClick={this.props.onClick}
          data-test={this.props['data-test']}
        >
          {this.renderTileOverlay()}
          {this.renderCameoContent(snap)}
          {overlayComponent}
        </div>
      );
    }
    return this.renderEmptyPreview();
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(StoryTopsnapPreview);
