import classNames from 'classnames';
import React from 'react';

import style from './VideoPreviewLoadingBar.scss';

type Props = {
  progressPercentage: number;
  className: string;
};

type State = {
  prevProgressPercentage: number;
  isDecreasingPercentage: boolean;
};

export class VideoPreviewLoadingBar extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    // We need to know if the percentage has decreased so that we can remove the transition so that the loading bar
    // doesn't look like it's going back
    const isDecreasingPercentage = props.progressPercentage < state.prevProgressPercentage;
    return {
      prevProgressPercentage: props.progressPercentage,
      isDecreasingPercentage,
    };
  }

  state = {
    prevProgressPercentage: 0,
    isDecreasingPercentage: false,
  };

  render() {
    const { className, progressPercentage } = this.props;
    const { isDecreasingPercentage } = this.state;
    return (
      <div className={classNames(style.loadingBarContainer, className)}>
        <div
          className={classNames(style.loadingBar, { [style.decreasingPercentage]: isDecreasingPercentage })}
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    );
  }
}

export default VideoPreviewLoadingBar;
